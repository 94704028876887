@import "color.css";

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-contact {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  text-align: center;
}

.icon-support {
  font-size: 30px;
  color: #4185cc;
}
