.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  padding: 150px 0;
}

.login-page-form {
  border-radius: 10px;
}

.login-page-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
