@import "./color.css";

html,
body,
#root,
#root > * {
  height: 100%;
}

.Toastify {
  height: 0 !important;
}

body {
  padding: 0;
  margin: 0;
  background-color: #fffcf8;
}

.content {
  margin: 0 auto;
  margin-top: 25px;
  width: 80%;
}

.content-product-page {
  margin: 0 auto;
  width: 100%;
}

.header {
  height: 48px;
  box-shadow: 1px 0px 5px gray;
}

.footer {
  margin-top: 100px;
  margin-bottom: 100px;
}
